import '../i18n';

import { BackToTop } from 'material-ui-back-to-top';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import NavbarWhite from '../components/navbar/NavbarWhite';
import React from 'react';
import TermsBody from '../components/TermsBody';
import { useTranslation } from 'react-i18next';

const TermsPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t('seo.page.terms.title')}</title>
      </Helmet>
      <BackToTop size="small" buttonPosition={{ bottom: 20, right: 20 }} />
      <NavbarWhite />
      <TermsBody />
      <Footer />
    </div>
  );
};

export default TermsPage;
