import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TermsBodyClient = () => {
  const { t } = useTranslation();
  const [shapyaTerms, setShapyaTerms] = React.useState([]);

  React.useEffect(() => {
    getShapyaTerms();
  }, []);

  const getShapyaTerms = async () => {
    const dataTerms = await fetch('https://app.shapya.com/api/terms/5');
    const dataTermsJson = await dataTerms.json();
    setShapyaTerms(dataTermsJson);
  };

  let idShapyaTerms = shapyaTerms.id;
  let versionShapyaTerms = shapyaTerms.version;
  let termstypeShapyaTerms = shapyaTerms.termstype;
  let contentShapyaTerms = shapyaTerms.content;

  return (
    <React.Fragment>
      <h1 className="shapyaTitle shapyaMain">{t('terms.title')}</h1>

      <br />
      <small className="shapyaText shapyaDarkGrey">
        <b>Terms Type:</b> {termstypeShapyaTerms}
      </small>
      {' - '}
      <small className="shapyaText shapyaDarkGrey">
        <b>Version:</b> {versionShapyaTerms}
      </small>
      <br />
      <br />
      <Typography
        align="justify"
        variant="subtitle2"
        style={{ whiteSpace: 'pre-wrap', marginBottom: '15px' }}
      >
        <span className="shapyaText">{contentShapyaTerms}</span>
      </Typography>
    </React.Fragment>
  );
};

export default TermsBodyClient;
